//
//	Chat
//

.chat-container {
	display: flex;
	flex-wrap: wrap;
}

.chat-group {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: $spacer * 0.75;

	> * {
		margin-right: $spacer * 0.25;
	}
}

.chat-avatar {
	display: flex;
	align-items: flex-start;
	margin-right: 0.5rem;
}

.chat-messages {
	// flex-grow: 1;
	max-width: 80%;
  position: relative;
}
.chat-zone{  
  flex-direction: row;
}
.chat-message {
	@include border-radius($border-radius * 1.5);

	padding: $spacer;
	background-color: #f4f4f4;

	@include dark-mode {
		background-color: $gray-800;
	}

	&:not(:last-child) {
		margin-bottom: $spacer * 0.25;
	}

	&:not(.chat-message-reply) {
		&:not(:first-of-type) {
			@include border-top-start-radius($border-radius * 0.5);
		}

		&:not(:last-of-type) {
			@include border-bottom-start-radius($border-radius * 0.5);
		}
	}

	&.chat-message-reply {
		&:not(:first-of-type) {
			@include border-top-end-radius($border-radius * 0.5);
		}

		&:not(:last-of-type) {
			@include border-bottom-end-radius($border-radius * 0.5);
		}
	}
}

.chat-message-reply {
	// background-color: tint-color($brand, 20%);
  background-color: #D2DCFF;
	color: #333;
	@include dark-mode {
		background-color: rgba-to-rgb(rgba($info, 0.3), $dark);
	}
}

.chat-group-reply {
	justify-content: flex-end;
}
// .chat-send-message {}
.send-btn{
  position: absolute !important;
  right: 1.5rem;
  bottom: 1.5rem;
  // background-color: rgb(109, 7, 14);
  color: #6d070e;
  border: none !important;
  &:hover{
    // background-color: #6d070e;
    color: #6d070e;
    border: none !important;
  }
  &:active{
    border: none !important;
  }
  &.disabled{
    background: none !important;
    border: none !important;
    color:#8a8a8a;
  }
}
.upload-btn{
	position: absolute !important;
	right: 3.6rem;
	bottom: 1.5rem;
	// background-color: rgb(109, 7, 14);
	color: #a7a7a7;
	border: none !important;
  font-weight: normal !important;
	&:hover{
	  // background-color: #6d070e;
	  color: #6d070e;
	  border: none !important;
	}
	&:active{
	  border: none !important;
	}
	&.disabled{
	  background: none !important;
	  border: none !important;
	  color:#8a8a8a;
	}	
}

// block-kit
.block{
	&-kit{
		width: 240px;
    margin:8px 70%;
    background-color: white;
    border: 0px;
    border-radius: 8px;
    box-sizing: border-box;
    overflow: hidden;
		box-shadow: 0 1.2rem 2rem rgb(0 0 0 / 10%);
		&-head:first-child{
			text-align: center;
			background:#ffd35b url(../../assets/error-warning-fill.svg) 3% 50% no-repeat;
		}
		&-title{
			margin: 0px 0px 8px;
			padding: 16px 16px 12px;
			color: rgb(17, 17, 17);
			font-size: 14px;
			line-height: 20px;
			font-weight: bold;
			background-color: rgb(213, 234, 255);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	&-mg{
		margin: 0px;
    position: relative;
    box-sizing: border-box;
	}
	&-tm{
		margin: 8px 0;
    position: relative;
    box-sizing: border-box;
	}
	&-lg{
		margin: 0px 8px;
    position: relative;
    box-sizing: border-box;
	}
	&-link{
		margin: 0px 0px 8px;
    padding: 8px;
    font-size: 14px;
    line-height: 20px;
    color: rgb(17, 17, 17);
    word-break: break-all;
    overflow-wrap: break-word;
    white-space: pre-wrap;
	}
	&-btn{
		width: 100%;
    padding: 10px 8px 11px;
    line-height: 19px;
    border: 0px;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 13px;
    color: rgb(17, 17, 17);
    background-color: rgb(241, 241, 241);
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
		&-true{
			width: 100%;
			padding: 10px 8px 11px;
			line-height: 19px;
			border: 0px;
			border-radius: 4px;
			box-sizing: border-box;
			font-size: 13px;
			color: rgb(17, 17, 17);
			background-color: rgb(213, 234, 255);
			cursor: pointer;
			text-align: center;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&-false{
			width: 100%;
			padding: 10px 8px 11px;
			line-height: 19px;
			border: 0px;
			border-radius: 4px;
			box-sizing: border-box;
			font-size: 13px;
			color: rgb(17, 17, 17);
			background-color: rgb(255, 210, 210);
			cursor: pointer;
			text-align: center;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	&-flex{
		display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin: 0px 0px 8px;
    padding: 8px;
		div{
			flex-grow: 1;
			padding: 8px;
		}
		&-head{
			width: 56px;
			flex-shrink: 0;
			font-size: 14px;
			line-height: 20px;
			color: rgb(17, 17, 17);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&-text{
			margin: 0px 0px 0px 8px;
			padding: 8px;
			font-size: 14px;
			line-height: 20px;
			color: rgb(17, 17, 17);
			word-break: break-all;
			overflow-wrap: break-word;
			white-space: pre-wrap;
		}
	}
}
// chat list
.card-header.chat-sub-tit{
	padding:0.825rem 1.31rem !important;
}
.card-body.chat-sub-list{
	padding:0.825rem 1.31rem !important;
	// padding-top: 0 !important;
}

// 채팅방정보
.room-title{
	padding: 0.825rem 1.31rem !important;
}
.accordion-button {
	min-height: 3rem !important;
	font-size: 1.1rem !important;
	font-weight: 600 !important;
}
.accordion-icon {
  font-size: 2rem !important;
}
.chat{
	&-empty{
		height: 100%;
		background: url(../../assets/visual01.svg) 50% 50% no-repeat;
		background-size: 50%;
		display: flex;
		justify-content: start;
		align-items: center;
		flex-direction: column;
		padding-top:6rem;
		font-size: 2rem;
		color:#aaa;
    opacity: 0.6;
	}
	&-head-zone{
		border-bottom: 1px #eee solid !important;
    padding-bottom: 0.5rem !important;
	}
	&-foot{
		&-zone{
			border-top: 1px #eee solid !important;
      position: relative;
		}
	}
	&-body{
		background: #fff;	
    position: relative !important;	
	}
	&-count{
		right: 0.15rem;
		bottom:-1.5rem;
		color:#aa7d00;
		font-size: 00.825rem;
	}
	&-user{
		&-info{
			display: flex;
			align-items: center;
			& > div{
				margin:0 0.25rem;
				font-size:1rem;
			}
			& > div.time{
				color: #888;
			}
		}
	}
}
.chat-message > pre {
	display: block;
	margin-top: unset;
	margin-bottom: unset;
	overflow: auto;
	font-size: unset;
	font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, Helvetica Neue, Segoe UI, Apple SD Gothic Neo, Noto Sans KR, Malgun Gothic,;
}
.chat-day {
	display:flex;
	flex-basis:100%;
	align-items:center;
	color:rgba(0,0,0,0.35);
	font-size:14px;
	margin:8px 0px;
}
.chat-day::before{
	content:"";
	flex-grow:1;
	margin:0px 16px;
	background:rgba(0,0,0,0.1);
	height:1px;
	font-size:0px;
	line-height: 0px;
}
.chat-day::after {
	content:"";
	flex-grow:1;
	margin:0px 16px;
	background:rgba(0,0,0,0.1);
	height:1px;
	font-size:0px;
	line-height: 0px;
}
.chat-group.chat-group-reply{
	& > .chat-avatar{
		display: none;
	}
	& > .chat-messages{
		& > .chat-user-info{
			& > .name{
				display: none;
			}
		}
	}
}
