//
// Custom
//

@use 'sass:map';

@import 'navigation';
@import 'chat';
@import 'board';
@import 'home';

input:focus,
select:focus{
  box-shadow: none !important;
  border-color: #222222 !important;
}
textarea:focus{
  box-shadow: none !important;
}
.form-control{
  border: 1px solid #ced4da;
  border-radius: 0 !important;
  padding: 0.75rem;
  background-color: #fff;
}   
.form-select{
  padding: 0.75rem 2.25rem 0.75rem 0.75rem;
  border-radius: 0 !important;
} 
.global-search{
  display: flex;
  flex-direction: column;  
  align-items: flex-end;
  input{
    width: 25%;
  }
}
.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s !important;
  -webkit-text-fill-color:rgb(80, 80, 80);
  box-shadow: none;
}
input:-webkit-internal-autofill-selected {
  transition: background-color 9999s ease-in-out 0s !important;
}
// 일반
.logo{
  width: 100%;
  height: auto;
}
// form-dxstb
.formstb{
  &-line{
    border:none;
    border-bottom: 1px rgb(167, 167, 167) solid;
    background-color: transparent;
    box-shadow: none;
    &:focus{
      background-color: transparent;
      border:none;
      border-bottom: 1px rgb(255, 255, 255) solid;
    }
  }
}

// card-dxstb
.card{
  &-box{   
    // background-color: rgba(255, 255, 255, 0.8 );
    box-shadow: 0px 5px 15px 5px rgb(0, 0, 0, 0.1) !important;
    border: 1px #4B4DFF solid;
    &-head{
      padding: 2.5rem !important;
      padding-top:0;
      background-color: transparent;
    }
    &-body{
      padding: 2.5rem !important;
      padding-top:0;
    }
    &-foot{
      padding: 2.5rem !important; 
      padding-top:0;     
      background-color: transparent;
      color: #4B4DFF;
    }
  }  
  &-none{
    background: none !important;
    &-head{
      padding: 2.5rem !important;
      padding-top:0;
      background: none !important;
    }
    &-body{
      padding: 2.5rem !important;
      padding-top:0;
    }
    &-foot{
      padding: 2.5rem !important; 
      padding-top:0;     
      background-color: transparent;
    }
  }
}

// 조직도
.orgchart-container::-webkit-scrollbar-track{
	background-color: white;
}
.orgchart-container {
	// border: 0 !important;
	background-color: white;
	// height: 100%;
	.orgchart {
		&.departTree {
			background-image: unset !important;
			background-color: white;
			height: inherit;
			
		}		
		ul {
			justify-content: center;
		}
		.border-danger {
			border-color: rgb(83, 83, 83) !important;
		}

		li::before,
		li::after {			
			border-top-color: black !important;
			border-top-width: 1px;
		}
		
		//li:only-child::before,
		//li:only-child::after,
		.oc-node:not(:only-child)::after,
		li:only-child::before,
		.oc-node::before,
		.oc-node::after {
			width: 1px;
			height: 15px;
			background-color: black !important;
		}
		li::before {
			left: -1px;
		}
		.oc-node::before {
			top: -12px;
		}
		.oc-node::after {
			bottom: 24px;
		}
		.oc-node {
			// height: 150px;
			// background: none;
			padding:5px;
		}
		.oc-node.selected {
			border: none;
		}
		.oc-node:hover {
			background: none;
		}
		
		.oc-node{
			.oc-edge.verticalEdge.topEdge.oci.oci-chevron-down::before,
			.oc-edge.verticalEdge.topEdge.oci.oci-chevron-up::before,
			.oc-edge.verticalEdge.bottomEdge.oci.oci-chevron-up::before,
			.oc-edge.verticalEdge.bottomEdge.oci.oci-chevron-down::before,
			.oc-edge.horizontalEdge.rightEdge.oci.oci-chevron-left::before,
			.oc-edge.horizontalEdge.rightEdge.oci.oci-chevron-right::before,
			.oc-edge.horizontalEdge.leftEdge.oci.oci-chevron-left::before,
			.oc-edge.horizontalEdge.leftEdge.oci.oci-chevron-right::before{
				content: "";
				display: inline-block;
				box-sizing: border-box;
				vertical-align: text-bottom;
				width: 0.8rem;
				height: 0.8rem;
				border-width: 0 0.2rem 0.2rem 0;
				border-style: solid;
				border-color: #000;
				background: transparent;
				
				
			}
		}
	}
	
}
.userDiv:hover{
	background: rgba(179, 179, 188, 0.72);	
}

  .oci {
	display: block;
	position: relative;
	font-style: normal;
	font-family: Arial;
  }
// 트리
.rstcustom__collapseButton::after, 
.rstcustom__expandButton::after {
	border-top-color: $dark-border-color !important;
	border-width: 5px !important;
	border-left-width: 5px !important;
	border-right-width: 5px !important;
}
.rstcustom__row {
	padding: 0px !important;
}

// Calander
.rbc-calendar {
	.rbc-month-view {
		border-color: $table-border-color;
		.rbc-month-header {
			.rbc-header {
				border-bottom: 1px #e7e7e7 solid;
			}
		}
	}
	.rbc-header {
		font-size: inherit;
	}
}

// 캘린더
.rbc-event {
	background-color: unset;
	font-weight: inherit;
	color: initial;
	padding: 0.2rem 0.5rem;
}

// 로그인 배경
.login{
	&-bg{
		background:#fff url(../../assets/bg-org.png) 50% 50% no-repeat;
    // background:#1a1a1a url(../../assets/bg-org.png) 50% 50% ;
		// background-size:contain;
    background-size: cover;
    // background: #f4f4f4;
	}  
  &-wrap{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  &-box{
    width: 90% !important;
    margin: 0 auto;
    &-text{
      width: 90% !important;
      margin: 0 auto;
      .stb-01{
        font-size: 2.4rem;
        color:rgb(147, 190, 255);
        margin-bottom: 1rem;
        text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
      }
      .stb-02{
        font-size: 3.5rem;
        line-height: 120%;
        font-weight: bold;
        color:#fff;
        text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
        margin-bottom: 0.825rem;
      }
      .stb-03{
        font-size: 1.5rem;
        color:#fff;
      }
    }
  }
}
@media  (max-width:767px) {
  .login-box-text{
    display: none !important;
  }
}
.text-color-white{
	color:#fff !important;
}
.f-n{
  font-weight: normal !important;
}


// 조직도
.chart-foot-info{
	background: #f4f4f4 ;
	padding:0.5rem;
	margin-left: 0.25em;
	margin-right: 0.25em;
	border-radius: 0.25rem;
}
.layer-foot{
	background: #f4f4f4;
}
.org{
	&-head{
		h4{
			margin: 0;
		}
		&-name{
			font-weight: 600;
			font-size: 1.25rem;
			padding:0.5rem;
			background:#f4f4f4;
			width: 100%;
			margin-top: 1rem;
			border-radius: 0.25rem;
		}
	}	
}

//기타
.empty-box{
	background:url(../../assets/undraw_filter_re_sa16.svg) 50% 50% no-repeat; 
	height: 100%;
	background-size: 70%;
	text-align: center;
}
.empty-box-se{
	background:url(../../assets/taking.svg) 50% 50% no-repeat; 
	height: 400px;
	background-size: 50%;
	text-align: center;
	padding:2rem;
}
.empty-list{
  height: 50vh !important;
  background: url(../../assets/list-empty.svg) 50% 30% no-repeat;
  background-size: contain;
  margin:2rem;
  opacity: 0.6;
  text-align: center;
}
.select-user{
	position: relative;
	&:after{
		content: "";
		border:1px solid #d7000f;
		position: absolute;
		width: 100%;
		height: 42px;
		top:0;
		left:0;
		background:url(../../assets/arrow-right-fill.svg) 99% 50% no-repeat; 
		// margin-left: -80px;
	}
	&-box{
		padding:0.8rem;
		background: #fff;
		border-radius:1rem;
	}
}
.table-modern.check-center{
	.form-check{
		.form-check-input{
			float: none;
		}
	}
}
.table-list{
  thead{
    border:none;
    text-align: center;
  }
  td{
    vertical-align: middle;
    text-align: center;
    &:nth-child(2){
      text-align: left;
    }
  }
}
// 채팅 
.chat-message{
  word-break:break-all;
  white-space:pre-line;
}

.chat-user-tbody {
display: block; /* to enable vertical scrolling */
height: 80vh; /* e.g. */
overflow-y: scroll; /* keeps the scrollbar even if it doesn't need it; display purpose */
}
  
.chat-user-tr {
display: table; /* display purpose; th's border */
width: 100%;
box-sizing: border-box; /* because of the border (Chrome needs this line, but not FF) */
}
.chat-file-box{
  // border:1px rgb(221, 221, 221) solid;
  background: #f4f4f4;
} 
.file-erro{
  background: #f4f4f4;
}
.bage-room{
  display: inline-block;
  background-color: #222222;
  color: #fff;
  border-radius: 4px;
  padding:2px 6px;
  margin-left: 4px;
  font-size: 12px;
}
.file-box{
  border-radius: 8px;
  cursor: pointer;
  &-info{
    line-height: 110%;
    margin-left:1rem;
  }
  &-icon{
    font-size: 2.2rem;    
  }
  &:hover{
    background: #f4f4f4;
  }
}
.list-inline-item > a{
  text-decoration: none !important;
}
a:visited{
  text-decoration: none !important;
}
.message-img{
  background: #fff;
  border-radius: 6px;
  margin:0 0.25rem;
}

.card-body-scrollable {
	overflow-x: hidden !important;
}
.fs-big{
  font-size: 24px;
  color: #d7000f;
}

// 2022.12.26  IRok 추가
// ###################################  file uploader css #####################################

.overlay {
	position: relative;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
  
	.drag-container {
	  .text {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		transform: translate(-50%, -50%);
		i {
		  font-size: 35pt;
		}
	  }
  
	  display: inline;
	  color: $info;
	  border: dashed $info 2px;
	  position: absolute;
	  z-index: 1000000;
	  top: 0;
	  right: 0;
	  bottom: 0;
	  left: 0;
	  -webkit-animation-name: zoomIn;
	  animation-name: zoomIn;
	  -webkit-animation-duration: 0.1s;
	  animation-duration: 0.1s;
	  text-align: center;
	  background: hsla(0, 0%, 100%, 0.8);
	  font-size: 42px;
	  -webkit-box-align: center;
	  -ms-flex-align: center;
	  align-items: center;
	  -webkit-box-pack: center;
	  -ms-flex-pack: center;
	}
  }
  
  .drag-container {
	display: none;
  }
  
  .paddingIm {
	padding: 0 !important;
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
  }
  .video-container iframe,
  .video-container object,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  .cursor-pointer {
	  cursor: pointer;
  }

  .marker-yellow {
    background-color: #ffcd4e;
  }
  .marker-green {
    background-color: rgb(25, 156, 25);
  }
  .marker-pink {
    background-color: rgb(255, 121, 183);
  }
  .marker-blue {
    background-color: rgb(60, 122, 255);
  }
  .marker-red {
    background-color: rgb(255, 52, 52);
  }
  .pen-green {
    background-color: rgb(155, 255, 155);
  }
  .profile{
    &-head{    
      padding: 3rem;
      background: #f4f4f4;
      &-info{
        text-align: center;
        line-height: 200%;
        margin-top: 1rem;
        font-size: 1.5rem;
        &-company{
          font-size: 1.25rem;
          line-height: 140%;
          font-weight: normal;
        }
      }
    }
    &-info{
      display: flex;
      
      &-text{
        margin-left: 3rem;
        border-top: 1px #ccc solid;
        padding-top:1.5rem;      
      }
      input:disabled{
        background: #f4f4f4 !important;
      }
    }
  }
  .dash-img{
    &-time{
      background: url(../../assets/Time.png) 0 0 no-repeat;
      background-size: cover;
      width: 255px;
      height: 100%;
    }
  }
  .ab-btn{
    background: #d7000f;
    color:#fff;
    padding:0.5rem 1rem;
    position: fixed;
    top:1rem;
    right: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 0.25rem;
    cursor: pointer;
    text-decoration: none;
    &:hover{
      background: #fff;
      color: #d7000f;
    }
  }

  table.table-stb2 {
	border-spacing: 0;
	border-collapse: separate;
	thead {
	  tr {
		position: sticky;
		top: 0;
		th {
		  border-bottom: 2px solid currentColor !important;
		  white-space: nowrap !important;
		}
	  }
	}
	tbody {
		tr {
			td {
				white-space: nowrap !important;
				padding-left: 5px;
				padding-right: 5px;
			}
		}
	}
  }
  .browser-noti{
    display: block;
    background: #333;
    color:#fff;
    margin: 0 -0.75rem;
    box-sizing: border-box;
    padding:0.5rem 1rem;
    font-size: 0.825rem;
  }
.aside .navigation-link[aria-expanded=true] .navigation-link-info i {
    color: #333 !important;
}
.navigation-item:nth-child(5), 
.navigation-item:nth-child(6){
  padding-top:0 !important;
  padding-bottom:0 !important;
}

// 채팅 탭 스타일 변경
.nav-link{
  padding: 1rem 0 !important;
  background: #fcfcfc !important;
  color: rgb(126, 126, 126) !important;
  border-bottom: 1px #f4f4f4 solid !important;
  &.active{
    background: #fff !important;
    color: #2F3268 !important;
    border-bottom: 1px #fff solid !important;
    font-weight: bold;
  }
}
.page404{
  background: url(../../assets/visual01-op.svg) 50% 50% no-repeat;
  background-size: 50%;
}
.ml20{
  margin-left: 5rem !important;
}
.line-remove{
  border:none !important;
  .btn-close{
    width: 6rem !important;
    height: 6rem !important;
  }
}
.system-box{
  display: flex;
  justify-content:flex-start;
  flex-wrap: wrap;
  margin:2rem auto;
  width: 70%;
}
.system-box li{
  width: 20%;
  position: relative;
  margin:1rem 0;
  border-radius: 1rem;  
  /* background: #362A81; */
  color: #333;
  font-size: 1rem;
  cursor: pointer;
}
.system-box li:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.system-box-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  padding:2rem 1rem;
  text-align: center; 
  display: flex;
  flex-direction: column;
  justify-content: end; 
}
.system-box-inner.box01{
  background: url(../../assets/ct01.svg)50% 40% no-repeat;
  background-size: 45%;
  transition: background-size .3s ease;
  -moz-transition: background-size .3s ease;
  -web-kit-transition: background-size .3s ease; 
}
.system-box-inner.box02{
  background: url(../../assets/ct02.svg)50% 40% no-repeat;
  background-size: 45%;
  transition: background-size .3s ease;
  -moz-transition: background-size .3s ease;
  -web-kit-transition: background-size .3s ease;
}
.system-box-inner.box03{
  background: url(../../assets/ct03.svg)50% 40% no-repeat;
  background-size: 45%;
  transition: background-size .3s ease;
  -moz-transition: background-size .3s ease;
  -web-kit-transition: background-size .3s ease;
}
.system-box-inner.box04{
  background: url(../../assets/ct04.svg)50% 40% no-repeat;
  background-size: 45%;
  transition: background-size .3s ease;
  -moz-transition: background-size .3s ease;
  -web-kit-transition: background-size .3s ease;
}
.system-box-inner.box05{
  background: url(../../assets/ct05.svg)50% 40% no-repeat;
  background-size: 45%;
  transition: background-size .3s ease;
  -moz-transition: background-size .3s ease;
  -web-kit-transition: background-size .3s ease;
}
.system-box-inner.box06{
  background: url(../../assets/ct06.svg)50% 40% no-repeat;
  background-size: 45%;
  transition: background-size .3s ease;
  -moz-transition: background-size .3s ease;
  -web-kit-transition: background-size .3s ease;
}
.system-box-inner.box07{
  background: url(../../assets/ct07.svg)50% 40% no-repeat;
  background-size: 45%;
  transition: background-size .3s ease;
  -moz-transition: background-size .3s ease;
  -web-kit-transition: background-size .3s ease;
}
.system-box-inner.box08{
  background: url(../../assets/ct08.svg)50% 40% no-repeat;
  background-size: 45%;
  transition: background-size .3s ease;
  -moz-transition: background-size .3s ease;
  -web-kit-transition: background-size .3s ease;
}
.system-box-inner.box09{
  background: url(../../assets/ct09.svg)50% 40% no-repeat;
  background-size: 45%;
  transition: background-size .3s ease;
  -moz-transition: background-size .3s ease;
  -web-kit-transition: background-size .3s ease;
}
.system-box-inner.box10{
  background: url(../../assets/ct10.svg)50% 40% no-repeat;
  background-size: 45%;
  transition: background-size .3s ease;
  -moz-transition: background-size .3s ease;
  -web-kit-transition: background-size .3s ease;
}
.system-box-inner.box11{
  background: url(../../assets/ct11.svg)50% 40% no-repeat;
  background-size: 45%;
  transition: background-size .3s ease;
  -moz-transition: background-size .3s ease;
  -web-kit-transition: background-size .3s ease;
}
.system-box-inner.box01:hover,
.system-box-inner.box02:hover,
.system-box-inner.box03:hover,
.system-box-inner.box04:hover,
.system-box-inner.box05:hover,
.system-box-inner.box06:hover,
.system-box-inner.box07:hover,
.system-box-inner.box08:hover,
.system-box-inner.box09:hover,
.system-box-inner.box10:hover,
.system-box-inner.box11:hover{
  background-size: 48%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
}
ul li {
  list-style: none;
}
.inner-subtitle {
  text-align: center;
  font-size: 1.5rem;
  margin-top: 0.5rem;
  color: #888;
}
.ta-c{
  text-align: center !important;
}
.labeling{
  display: flex;  
  align-items: center;
}
.btn{
  &-y{
    border-radius: 2rem;
    background-color: #d7000f;
    color: #fff;
    margin:0 0.625rem;
    font-size: 00.825rem !important;
  }
  &-n{
    border-radius: 2rem;
    background-color: #333;
    color: #fff;
    font-size: 00.825rem !important;
  }
}
.sec{
  margin:0 0.5rem;
  color: #ffa8ae;
}
.mini-size{
   width: 100% !important;
}
.my-info-menu button,
.my-info-menu div button
{
  color: #333;
  font-weight: normal !important;
}
.alram-box{
  margin-right: 0.5rem;  
  padding: 0.875rem 0.875rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}