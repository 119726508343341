//
//  Content
//

.content {
	@include padding(map-get($content, padding-y) map-get($content, padding-x));

	display: flex;
	flex: 1 0 auto;
	background-color: #f4f4f4;

	.header + & {
		@include media-breakpoint-down(map-get($aside, mobile-breakpoint)) {
			padding-top: map-deep-get($header, mobile, height);
		}
	}
}
