//
//	Modal
//

.modal-content {
	@include dark-mode {
		background-color: $dark-modal-content-bg;
	}
}

.modal {
	&-backdrop {
		@include backdrop-filter-blur($modal-backdrop--blur);

		background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);

		&.show {
			opacity: 1;
		}
	}
}
// 속성정의가 node-modules 되어있어 추가함
.modal-title {
	font-weight: 600;
}
.modal-header {
	border-bottom: 1px #E5E5E5 solid;
}